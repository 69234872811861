import request from '@/utils/request'
const baseURL = '/exam.mock'

/**
 * 试卷列表
 * @param {*} params 
 * @returns 
 */
 export function list(params) {
  return request({
    url: `${baseURL}/list`,
    method: 'get',
    params
  })
}
/**
 * 生成模拟题
 * @param {*} params 
 * @returns 
 */
export function generate(params) {
  return request({
    url: `${baseURL}/generate`,
    method: 'get',
    params
  })
}