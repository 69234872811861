<template>
  <div class="generate-new-mock my-4" style="min-height: 660px;">
    <el-alert
      description="选择对应的试卷类型和等级后，点击【生成模拟题试卷】，即可开始模拟题考试。"
      type="info"
      show-icon
      :closable="false"
    ></el-alert>
    <div class="flex flex-wrap md:justify-between">
      <el-form :inline="true" :model="form" class="flex flex-wrap mt-4 md:w-1/2 w-full">
        <el-form-item label="试卷学科">
          <el-select v-model="form.type" placeholder="学科">
            <el-option v-for="item of typeList" :key="item.val" :label="item.name" :value="item.val"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="试卷等级">
          <el-select v-model="form.level" placeholder="等级">
            <el-option v-for="item of levelList" :key="item.val" :label="item.name" :value="item.val"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" @click="generateDialog = true" icon="el-icon-document-add">生成模拟试卷</el-button>
        </el-form-item>
      </el-form>
      <el-form :inline="true" :model="query" class="flex flex-wrap mt-4 md:w-1/2 w-full">
        <el-form-item label="试卷类型">
          <el-select v-model="query.type" placeholder="请选择">
            <el-option v-for="item of typeList" :key="item.val" :label="item.name" :value="item.val"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="试卷等级">
          <el-select v-model="query.level" placeholder="请选择">
            <el-option v-for="item of levelList" :key="item.val" :label="item.name" :value="item.val"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="init()" icon="el-icon-search">查询已生成</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="mock-list">
      <div v-if="examList.length > 0">
        <div class="exam-list">
          <el-card 
            shadow="hover" 
            class="text-left mb-3"
            v-for="(item, index) of examList"
            :key="item.id"
          >
            <div class="flex flex-wrap">
              <div class="md:w-2/5 w-full pr-2">
                <p class="mb-4">{{ item.name }} <el-tag v-if="index === 0" type="danger" size="mini" effect="dark" style="vertical-align: text-top;">最新</el-tag></p>
                <p class="mb-4">生成时间：{{ item.create_time }}</p>
              </div>
              <div class="md:w-1/5 w-1/2">
                <p class="mb-2" v-if="typeName(item.type)">学科：{{ typeName(item.type) }}</p>
                <p class="mb-2" v-if="levelName(item.level)">等级：{{ levelName(item.level) }}</p>
                <p class="mb-2" v-if="item.is_wrong_set">试卷类型：【错题重做】</p>
                <p class="mb-2" v-else>试卷类型：【模拟题】</p>
              </div>
              <div class="md:w-1/5 w-1/2">
                <p class="mb-2">题目数量：{{ item.question_num }}</p>
                <p class="mb-2">试卷总分：{{ item.score }}</p>
                <p class="mb-2">建议时长：{{ item.suggest_time }}</p>
              </div>
              <div class="md:w-1/5 w-full flex justify-end items-start">
                <el-button type="primary" size="small" icon="el-icon-edit" @click="goExamDetail(item)">开始考试</el-button>
              </div>
            </div>
          </el-card>
        </div>
        <el-pagination
          class="my-4 md:hidden"
          background
          small
          layout="prev, pager, next"
          :page-size="query.num"
          :total="examTotal"
          :current-page.sync="currentPage"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
        <el-pagination
          class="my-4 md:block hidden"
          background
          layout="prev, pager, next"
          :page-size="query.num"
          :total="examTotal"
          :current-page.sync="currentPage"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <el-empty v-else description="你还未生成模拟试卷"></el-empty>
    </div>
    <el-dialog
      title="生成模拟试卷确认"
      :visible.sync="generateDialog"
      width="350px"
    >
      <div class="text-lg w-1/2 text-left mx-auto">
        <p><span>试卷类型：</span><span>{{ typeNameByForm }}</span></p>
        <p><span>试卷等级：</span><span>{{ levelNameByForm }}</span></p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="generateLoading" @click="generateNewMock">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { list, generate } from '@/api/mock'

export default {
  name: 'MockGenerate',
  data() {
    return {
      form: {
        type: 0,
        level: 1
      },
      typeList: [
        { name: 'Scratch', val: 0 },
        { name: 'C++', val: 1 },
        { name: 'Python', val: 2 },
        { name: '机器人', val: 4 },
      ],
      levelList: [
        { name: '一级', val: 1 },
        { name: '二级', val: 2 },
        { name: '三级', val: 3 },
        { name: '四级', val: 4 }
      ],
      query: {
        type: '',
        level: '',
        page: 1,
        num: 9
      },
      examList: [],
      examTotal: 0,
      currentPage: 1,
      generateDialog: false,
      generateLoading: false,

    }
  },
  computed: {
    typeNameByForm() {
      return this.typeList.find(item => {
        return item.val === this.form.type
      })?.name
    },
    levelNameByForm() {
      return this.levelList.find(item => {
        return item.val === this.form.level
      })?.name
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    generateNewMock() {
      this.generateLoading = true
      generate(this.form).then((res) => {
        console.log(res)
        this.generateLoading = false
        this.generateDialog = false
        this.init()
        this.$message({
          message: '生成试卷成功！',
          type: 'success'
        })
      }).catch(() => {
        setTimeout(() => {
          this.generateLoading = false
        }, 1500);
        
      })
    },
    init() {
      list(this.query).then(res => {
        this.examList = res.data.list
        this.examTotal = res.data.count
      })
    },
    typeName(val) {
      return this.typeList.find(item => {
        return item.val === val
      })?.name
    },
    levelName(val) {
      return this.levelList.find(item => {
        return item.val === val
      })?.name
    },
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    goExamDetail(row) {
      const hrefData = this.$router.resolve({ 
        path: '/exam/detail',
        query: { id: row.id }
      })
      window.open(hrefData.href, 'exam-page')
    }
  }
}
</script>

<style lang="scss">
.generate-new-mock {
  .el-alert .el-alert__description {
    font-size: 16px !important;
    line-height: 28px !important;
  }
}
</style>